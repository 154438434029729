import React, { useEffect, useState } from 'react'
import { UsersReviewCarouselProps } from 'interfaces/CarouselInterfaces'

import CardReview from './CardReview'
import SwiperCore, {
  Autoplay,
  Navigation,
  Pagination,
} from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleLeft,
  faAngleRight,
} from '@fortawesome/free-solid-svg-icons'
import { CarouselContainer } from './styled-components'

import 'swiper/css'
import 'swiper/css/pagination'
import { PaginationOptions } from 'swiper/types'
import { IGatsbyImageData } from 'gatsby-plugin-image'

SwiperCore.use([Navigation, Pagination, Autoplay])

const UsersReviewCarousel = ({
  reviews,
  activeReview,
  setActiveReview,
  link,
}: UsersReviewCarouselProps) => {
  const [reviewsData, setReviewsData] = useState<any[]>([])
  const pagination:
    | boolean
    | PaginationOptions
    | undefined = {
    clickable: true,
    renderBullet: (index: number, className: string) => {
      return `<span key=${index} class=${className}></span>`
    },
  }

  useEffect(() => {
    setReviewsData(reviews)
  }, [reviews])

  return (
    <CarouselContainer>
      {reviewsData.length > 0 && (
        <>
          <button
            className="swiper-button-prev"
            aria-label="Previous Slide"
          >
            <FontAwesomeIcon
              icon={faAngleLeft}
              className="arrow"
            />
          </button>
          <button
            className="swiper-button-next"
            aria-label="Next Slide"
          >
            <FontAwesomeIcon
              icon={faAngleRight}
              className="arrow"
            />
          </button>
          <Swiper
            spaceBetween={50}
            loop={true}
            autoplay={false}
            className="swiper"
            navigation={{
              prevEl: '.swiper-button-prev',
              nextEl: '.swiper-button-next',
            }}
            pagination={pagination}
            breakpoints={{
              // when window width is >= 480px
              768: {
                slidesPerView: 1,
              },

              1430: {
                slidesPerView: 2,
              },
            }}
            initialSlide={0}
          >
            {reviews.map(
              (
                review: {
                  profilePicture: {
                    gatsbyImage: IGatsbyImageData
                  }
                  fullName: string
                  designation: string
                  review: {
                    review: string
                  }
                },
                index: number,
              ) => {
                return (
                  <SwiperSlide
                    key={index}
                    className="swiper-slide"
                  >
                    {/* {variant === 'what-people-say' ? (
                    <ContentReview {...review} />
                  ) : ( */}
                    <CardReview
                      {...review}
                      index={index}
                      onClick={setActiveReview}
                      isActive={activeReview === index}
                    />
                    {/* )} */}
                  </SwiperSlide>
                )
              },
            )}
            {link && (
              <SwiperSlide className="swiper-slide linked">
                <a href={link} target="_blank">
                  <p>
                    Want to see more of our testimonials ?
                    <br />
                    head to our youtube channel
                  </p>
                </a>
              </SwiperSlide>
            )}
          </Swiper>
        </>
      )}
    </CarouselContainer>
  )
}

export default UsersReviewCarousel
