import styled from 'styled-components'

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  padding: 100px 165px;
  gap: 30px;
  ${({ theme }) => theme.placeholders.maxWidth}

  @media (max-width: ${({ theme }) => theme.breakpoints.wl}) {
    padding: 100px 100px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.wm}) {
    padding: 100px 50px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.wxs}) {
    padding: 20px;
  }
`

export const ArticleContainer = styled.article`
  display: flex;
  justify-content: space-between;
  gap: 30px;
  flex-wrap: wrap;
  @media (max-width: ${({ theme }) => theme.breakpoints.wxs}) {
    display: none;
  }

  a {
    width: calc(25% - 40px);
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.wm}) {
    a {
      width: calc(50% - 20px);
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.wxs}) {
    a {
      width: 100%;
      height: 222px;
    }
  }
`

export const ArticleItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  border-radius: 10px;
  border: 2px solid #06c68f;
  padding: 24px;
  height: 100%;
  transition: transform 0.3s ease-in-out;
  transform: scale(0.96);

  @media (max-width: ${({ theme }) => theme.breakpoints.ws}) {
    width: 100%;
    height: 222px;
    justify-content: center;
  }

  p {
    text-align: center;
  }

  .title {
    color: black;
    font-size: 18px;
    font-family: 'Poppins-SemiBold';
  }

  .description {
    font-size: 16px;
    font-family: 'Poppins';
    line-height: 24px;
    color: #636369;
  }

  &:hover {
    transform: scale(1);
  }
`

export const SalesMasteryContainer = styled.div`
  ${({ theme }) => theme.placeholders.maxWidth}
  width: 100%;
  overflow: visible;
  padding: 5%;
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  background-color: #f5f5f5;
  align-items: center;
  height: 300px;

  @media (max-width: ${({ theme }) => theme.breakpoints.ws}) {
    flex-direction: column-reverse;
    height: auto;
  }
`

export const CourseDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 45%;

  .description {
    font-size: 1rem;
    font-family: 'Poppins';
    color: #4c4c4c;
  }

  .link,
  .custom-button {
    width: 250px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.ws}) {
    width: 100%;

    .title {
      text-align: center;
    }

    .link,
    .custom-button {
      width: 100%;
    }
  }
`

export const CourseSvg = styled.div`
  width: 35%;
  transform: translateY(-20px);
  @media (max-width: ${({ theme }) => theme.breakpoints.ws}) {
    width: 50%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.wxs}) {
    width: 80%;
    transform: translateY(0);
  }
`
