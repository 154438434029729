import styled from 'styled-components'

export const SwiperContainer = styled.div<{ slideWidth: string }>`
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.wxs}) {
    display: none;
  }

  .swiper-container {
    .swiper-slide {
      width: ${({ slideWidth }) => slideWidth};
    }
  }
`
