import React from 'react'
import SwiperCore, { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { SwiperContainer } from './styled-components'

SwiperCore.use([Autoplay])

const PhotoSlider = ({ tabs }: { tabs: string[] }) => {
  return (
    <SwiperContainer>
      <Swiper
        loop={true}
        autoplay={true}
        speed={500}
        centeredSlides={true}
        slidesPerView={5}
        breakpoints={{
          // when window width is >= 320px
          320: {
            slidesPerView: 2,
          },

          // when window width is >= 480px
          480: {
            slidesPerView: 2,
          },

          // when window width is >= 640px
          640: {
            slidesPerView: 3,
          },
        }}
      >
        {tabs.map((tab, index) => {
          return (
            <SwiperSlide key={index} className="navigator-slide">
              <a
                href={`#navigation-index-${index > 0 && index < 3 ? 0 : index}`}
                key={index}
              >
                {tab}
              </a>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </SwiperContainer>
  )
}

export default PhotoSlider
