import styled from 'styled-components'

export const Wrapper = styled.section`
  padding: 100px 165px;
  ${({ theme }) => theme.placeholders.maxWidth}
  @media (max-width: ${({ theme }) =>
    theme.breakpoints.wl}) {
    padding: 40px 100px;
  }

  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wm}) {
    padding: 50px 50px;
  }

  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wxs}) {
    padding: 70px 20px;
  }

  .master-key {
    max-width: 800px;
    margin: 0 auto;
  }
`

export const ArticleContainer = styled.article`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin: 30px 0 50px 0;

  .screen {
    width: 40%;
    display: flex;
    justify-content: flex-end;

    .gatsby-image-wrapper {
      width: 60%;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: 50%;

    .title {
      display: flex;
      gap: 18px;
      align-items: center;

      .percentage {
        font-size: 80px;
        color: #06c68f;
        font-family: 'Poppins-Bold';
      }

      .label {
        font-family: 'Poppins';
        color: rgba(99, 99, 105, 1);
        line-height: 36px;
      }
    }

    .description {
      font-size: 16px;
      line-height: 24px;
      color: #636369;
      font-family: 'Poppins';
      padding: 0 !important;
    }
  }

  .responsive-percent {
    display: none !important;
  }

  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wxs}) {
    flex-direction: column;

    .screen {
      width: 100%;
      justify-content: center;
      .gatsby-image-wrapper {
        width: 80%;
      }
    }

    .content {
      width: 100%;
      align-items: center;
      .description {
        text-align: center;
        width: 50%;
      }

      a,
      a .custom-button {
        width: 100%;
        justify-content: center;
      }
    }

    .responsive-percent {
      display: flex !important;
    }

    .responsive-invert {
      display: none !important;
    }
  }
`

export const FlexArticleContainer = styled.article`
  display: flex;
  justify-content: space-between;
  gap: 30px;
  flex-wrap: wrap;

  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wxs}) {
    display: none;
  }
`

export const FlexArticleItem = styled.div`
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  padding: 25px;
  width: calc(25% - 40px);
  min-height: 333px;
  margin-block: 10px;
  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wm}) {
    width: calc(50% - 40px);
    text-align: left;
  }

  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wxs}) {
    width: 80% !important;
    min-width: 280px !important;
    height: 300px;
  }
`

export const ArticleIcon = styled.div`
  width: 32px;
  height: 32px;
  background: #06c68f;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ArticleTitle = styled.h6`
  font-family: 'Poppins-SemiBold';
  margin: 25px 0 8px 0;
  font-size: 16px;
`

export const ArticleDescription = styled.div`
  font-family: 'Poppins';
  font-size: 14px;
  line-height: 20px;
  color: #636369;
  white-space: break-spaces;

  ul {
    margin-top: 2px;
    margin-left: 10px;
  }
`
