import { GatsbyImage } from 'gatsby-plugin-image'
import { UsersReview } from 'interfaces/CarouselInterfaces'
import React, { useEffect, useState } from 'react'
import TPFiveStar from 'images/svg/FiveStar.svg'
import {
  ReviewContainer,
  ReviewContent,
  ReviewHeader,
  VerifiedContainer,
  VerifiedTextContainer,
} from './styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'

const Variants = {
  closed: {
    x: 0,
    opacity: 1,
    transition: {
      x: { stiffness: 1000 },
    },
  },
  open: {
    x: -20,
    opacity: 0,
    transition: {
      x: { stiffness: 1000 },
    },
  },
}

const invertedVariants = {
  closed: {
    x: 0,
    opacity: 1,
    transition: {
      x: { stiffness: 1000 },
    },
  },
  open: {
    x: 20,
    opacity: 0,
    transition: {
      x: { stiffness: 1000 },
    },
  },
}

export const tabVariants = {
  closed: {
    transition: {
      ease: 'easeInOut',
      staggerChildren: 0.5,
      delayChildren: 0.2,
    },
  },
  open: {
    transition: {
      ease: 'easeInOut',
      staggerChildren: 0.05,
      staggerDirection: -1,
      delay: 0,
    },
  },
}

const CardReview = ({
  profilePicture,
  fullName,
  designation,
  review,
  className,
  onClick,
  index,
  isActive,
  trustPilotStyle,
}: UsersReview) => {
  const [expanded, setExpanded] = useState(false)
  const isDesignation =
    designation !== undefined && designation !== '!'
  const handleClick = () => {
    setExpanded(true)
    if (onClick) {
      onClick(index)
    }
  }

  useEffect(() => {
    isActive ? setExpanded(true) : setExpanded(false)
  }, [isActive])
  return (
    <>
      <ReviewContainer
        className={className}
        slideHeight={250}
        onClick={handleClick}
        variants={tabVariants}
        animate={expanded ? 'open' : 'closed'}
      >
        <ReviewHeader
          animate={expanded ? 'open' : 'closed'}
          variants={Variants}
        >
          <div className="avatar">
            {profilePicture && (
              <GatsbyImage
                image={profilePicture.gatsbyImage}
                className="profilePic"
                alt="Profile pic"
              />
            )}
            <div className="name-container">
              <h6>{fullName}</h6>
              {isDesignation && <p>{designation}</p>}
            </div>
          </div>
          {trustPilotStyle && (
            <VerifiedContainer>
              <VerifiedTextContainer>
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  color="#6c6c85"
                />
                Verified
              </VerifiedTextContainer>
              <TPFiveStar width="120px" height="28px" />
            </VerifiedContainer>
          )}
        </ReviewHeader>
        <ReviewContent
          animate={expanded ? 'open' : 'closed'}
          variants={invertedVariants}
        >
          "{review.review}"
        </ReviewContent>
      </ReviewContainer>
    </>
  )
}

export default CardReview
