import styled from 'styled-components'

export const SwiperContainer = styled.div`
  width: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 20px 0 10px 0;

  .swiper-slide {
    display: flex;
    justify-content: center;
    a {
      font-family: 'Poppins-SemiBold';
      font-size: 1rem;
      color: #06c68f;
    }
  }

  @media (max-width: 860px) {
    display: flex;
  }
`
