import { CompanyLogo } from 'components/common'
import React from 'react'
import SwiperCore, { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { SwiperContainer } from '../UsersReview/styled-components'

interface PhotoSliderProps {
  photos: {
    src: string
    alt: string
    backgroundColor: string
  }[]
  gatsbyData: any
}

SwiperCore.use([Autoplay])

const PhotoSlider = ({ photos, gatsbyData }: PhotoSliderProps) => {
  return (
    <SwiperContainer className="photo-slider">
      {photos && (
        <Swiper
          loop={true}
          autoplay={true}
          speed={500}
          spaceBetween={40}
          centeredSlides={true}
          slidesPerView={4}
          className="swiper-photo"
          breakpoints={{
            // when window width is >= 320px
            320: {
              slidesPerView: 2,
            },

            // when window width is >= 480px
            480: {
              slidesPerView: 2,
            },

            // when window width is >= 640px
            640: {
              slidesPerView: 3,
            },
          }}
        >
          {photos.map((image, index) => {
            const Image = gatsbyData?.allFile.nodes.find(
              (Paths: { relativePath: string }) =>
                Paths.relativePath === image.src,
            )?.childImageSharp.gatsbyImageData
            return (
              Image && (
                <SwiperSlide key={index}>
                  <CompanyLogo
                    key={index}
                    image={Image}
                    alt={image.alt}
                    backgroundColor={image.backgroundColor}
                  />
                </SwiperSlide>
              )
            )
          })}
        </Swiper>
      )}
    </SwiperContainer>
  )
}

export default PhotoSlider
