import styled from 'styled-components'
import { motion } from 'framer-motion'

export const ReviewContainer = styled(motion.div)<{
  slideHeight?: number
}>`
  padding: 32px;
  display: flex;
  min-height: 250px;
  flex-direction: column;
  gap: 25px;
  border-radius: 10px;
  cursor: pointer;
  margin: 0 15px;
  height: ${({ slideHeight }) => !slideHeight && '250px'};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
`

export const ReviewHeader = styled(motion.div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  .avatar {
    display: flex;
    gap: 10px;

    .profilePic {
      width: 60px;
      height: 60px;
      border-radius: 50%;

      img,
      div {
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }
    }

    .name-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;

      h6 {
        font-size: 16px;
        font-family: 'Montserrat-Bold', sans-serif;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      p {
        font-size: 14px;
        font-family: 'Montserrat', sans-serif;
        color: #4b4b4b;
      }
    }
  }

  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wm}) {
    flex-direction: column;
    align-items: center;
  }
`

export const ReviewContent = styled(motion.p)`
  font-size: 16px;
  line-height: 26px;
  color: #4c4c4c;
  font-family: 'Montserrat', sans-serif;
  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wxs}) {
    font-size: 14px;
  }
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-line;
`

export const VerifiedTextContainer = styled.div`
  display: flex;
  gap: 5px;
  color: #6c6c85;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
`

export const VerifiedContainer = styled.div`
  display: flex;
  gap: 15px;
  color: #4b4b4b;
  align-items: center;
`

export const CarouselContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  // swiper styles
  .swiper {
    .swiper-slide{
      margin-bottom: 30px;
    }
    .swiper-pagination {
      bottom: 0px;

      .swiper-pagination-bullet{
        background: #baccc1;
      }

      .swiper-pagination-bullet-active{
        background: #06C68F;
      }
    }

    .linked {
      border-radius: 10px;
      cursor: pointer;
      min-height:  250px;
      height: 409px;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
      
      a {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          font-size: 1.25rem;
          font-family: 'Poppins-SemiBold', sans-serif;
          text-align: center;
          color: #414042;
          transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
          @media (max-width: ${({ theme }) =>
            theme.breakpoints.wxs}) {
            font-size: 1.1rem;
            transform: scale(0.96);
          }
        }
        &:hover {
          p{
            color: #06C68F;
            transform: scale(1);
          }
        }
      }
    }
  }
  

  .swiper-button-prev {
    left: -80px;
  }

  .swiper-button-next {
    right: -80px;
  }

  @media (max-width: ${({ theme }) =>
    theme.breakpoints.wm}) {
    width: 70%;
  }

  @media (max-width: ${({ theme }) =>
    theme.breakpoints.ws}) {
    width: 100%;
    .swiper-button-prev {
      display: none;
    }
  
    .swiper-button-next {
      display: none;
    }
  }

  .swiper-button-prev, .swiper-button-next {
    width: 65px;
    height: 100px;
    position: absolute;
    top: calc(50% - 20px);
    transform: translateY(-50%);
    transform: translateY(-50%);
    background-color: #fff;
    border: none;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);

    & > svg path {
      transition: all 0.3s ease-in-out;
    }

    &:hover {
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

      .arrow path {
        fill: #06C68F;
      }
    }

    &:focus {
      outline: none;
    }
`

export const SwiperContainer = styled.div`
  width: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  position: relative;
  

  .swiper-button-prev {
    left: -80px;
  }

  .swiper-button-next {
    right: -80px;
  }

  .swiper-button-prev, .swiper-button-next {
    width: 65px;
    height: 100px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: #fff;
    border: none;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);

    & > svg path {
      transition: all 0.3s ease-in-out;
    }

    &:hover {
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

      .arrow path {
        fill: #06C68F;
      }
    }

    &:focus {
      outline: none;
    }

    @media (max-width: ${({ theme }) =>
      theme.breakpoints.wm}) {
      display: flex;
    }
`

export const ReviewFooter = styled.p`
  font-size: 14px;
  font-family: 'Poppins-Bold', sans-serif;
`
