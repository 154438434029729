import { Link } from 'gatsby'
import React from 'react'
import { ArticleItem } from 'sections/Learners/ExploreCourses/styled-components'
import {
  ArticleDescription,
  ArticleIcon,
  ArticleTitle,
  FlexArticleItem,
} from 'sections/Learners/MasterKey/styled-components'
import SwiperCore, { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { SwiperContainer } from './styled-components'

SwiperCore.use([Autoplay])
const CustomCarousel = ({
  articles,
  variant,
}: {
  articles: {
    icon?: any
    title: string
    description: string
    list?: string[]
    route?: string
  }[]
  variant?: string
}) => {
  switch (variant) {
    case 'explore-courses':
      return (
        <SwiperContainer slideWidth={'75%'}>
          <Swiper
            loop={true}
            autoplay={false}
            spaceBetween={20}
            slidesPerView={'auto'}
            className="swiper-container"
          >
            {articles.map(
              ({ title, description, route }, index) => (
                <SwiperSlide key={index}>
                  {route && (
                    <Link to={route}>
                      <ArticleItem key={index}>
                        <p className="title">{title}</p>
                        <p className="description">
                          {description}
                        </p>
                      </ArticleItem>
                    </Link>
                  )}
                </SwiperSlide>
              ),
            )}
          </Swiper>
        </SwiperContainer>
      )
    default:
      return (
        <SwiperContainer
          className="responsive"
          slideWidth={'75%'}
        >
          <Swiper
            loop={true}
            autoplay={true}
            spaceBetween={80}
            slidesPerView={'auto'}
            className="swiper-container"
            breakpoints={{
              320: {
                spaceBetween: 90,
              },

              350: {
                spaceBetween: 40,
              },

              400: {
                spaceBetween: 30,
              },

              420: {
                spaceBetween: 0,
              },
            }}
          >
            {articles.map((article, index) => (
              <SwiperSlide key={index}>
                <FlexArticleItem key={index}>
                  <ArticleIcon>
                    <article.icon />
                  </ArticleIcon>
                  <ArticleTitle>
                    {article.title}
                  </ArticleTitle>
                  <ArticleDescription>
                    {article.description}
                    {article.list && (
                      <ul>
                        {article.list.map((item, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                    )}
                  </ArticleDescription>
                </FlexArticleItem>
              </SwiperSlide>
            ))}
          </Swiper>
        </SwiperContainer>
      )
  }
}

export default CustomCarousel
